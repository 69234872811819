<template>
  <section>
      <v-progress-linear
        :active="loadingBarState"
        :indeterminate="loadingBarState"
        absolute
        top
        color="light-blue accent-4"
      ></v-progress-linear>
        <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: "Exam",
  data: () => ({
    code: undefined
  }),
  computed: {
    loadingBarState: {
      get () {
        return this.$store.getters['getLoadingBar']
      },
      set (val) {
        this.$store.commit('updateLoadingBar', val)
      }
    },
    examToDoState: {
      get () {
        return this.$store.getters['exam/getExamToDo']
      },
      set (val) {
        this.$store.commit('exam/updateExamToDo', val)
      }
    }
  },
  mounted() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    this.code = this.$route.params.code
    this.$axios.get('exam/todo/' + this.code)
      .then((response) => {
        this.examToDoState = response.data
      })
      .catch((error) => {
        console.log(error)
        this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
      })
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
</style>
